import React from "react";
import SmartTextBox from "../lib/SmartTextBox";
import SmartSection from "../lib/SmartSection";
import $ from "jquery";
import SmartRadioButtons from "../lib/SmartRadioButtons";
import { getSelectedRequest } from "../../util/functions";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { Row, Badge } from "react-bootstrap";
import _ from 'lodash'

class CreateOnsiteRequest extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: false,
    };
  }

  handleInputChange = (event, options = {name: null, value: null }, runServerSave = true) => {
    let name = options.name == null ? event.target.name : options.name;
    let value = options.value == null ? event.target.value : options.value;

    if(runServerSave){
      this.props.updateServiceRequestObject(name, value);
      this.props.saveSR();
    }else{
      this.props.updateServiceRequestObject(name, value);
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    $.ajax({
      url: `/api/request/getActiveSR?selected_request_id=${getSelectedRequest()}`,
      dataType: "json",
      method: "GET",
      success: (result) => {
        this.props.replaceServiceRequestObject(result);
        this.setState({
          isLoading: false,
        });
      },
      error: () =>
        this.setState({ isLoading: false })
    });
  }

  render() {
    const nextOptions = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }
      
      this.props.saveSR_Now(this.props.nextPage);
    };

    const getValue = (path) => {
      return _.get(this.props.srObj, path)
    }

    return (
      <form ref={this.formRef} onSubmit={nextOptions} className="needs-validation" noValidate>
        <div className="col-12">
          <h3 className="text-center text-primary mt-3">
            Onsite Information
          </h3>
          {this.state.isLoading ? <SkeletonLoader count={11} width="100px" /> :
            <Row>
              <SmartSection
                id="onsiteContactSection"
                title="Onsite Contact"
              >
                <SmartRadioButtons
                  id="IsThereASeparateOnSiteContact"
                  label="Is there a separate onsite contact?"
                  valueList={["YES", "NO"]}
                  valueLabelList={["Yes", "No"]}
                  selectedValue={getValue('onsiteInfo.onSiteContact')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.onSiteContact"})}
                  invalidFeedback="Please provide a valid Is there a separate on-site contact."
                  required
                />
                <SmartTextBox
                  id="onsiteName"
                  label="Name"
                  maxLength="62"
                  value={getValue('onsiteInfo.onSiteName')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.onSiteName"})}
                  invalidFeedback="Please provide a valid Name."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.onSiteContact')]}
                  required
                />
                <SmartTextBox
                  id="onsiteCompany"
                  label="Company"
                  maxLength="50"
                  value={getValue('onsiteInfo.onSiteCompany')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.onSiteCompany"})}
                  invalidFeedback="Please provide a valid Company."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.onSiteContact')]}
                  required
                />
                <SmartTextBox
                  id="onsitePhone"
                  type="phone"
                  label="Phone"
                  value={getValue('onsiteInfo.onSitePhone')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.onSitePhone"})}
                  invalidFeedback="Please provide a valid Phone Number."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.onSiteContact')]}
                  required
                />
                <SmartTextBox
                  type="email"
                  maxLength="100"
                  id="onsiteEmail"
                  label="Email"
                  value={getValue('onsiteInfo.onSiteEmail')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.onSiteEmail"})}
                  invalidFeedback="Please provide a valid Email."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.onSiteContact')]}
                  required
                />
              </SmartSection>
              <SmartSection
                id="hvacInstallerSection"
                title="Gas Equipment Installation Contractor"
                workTypeShowList={[1, 2, 3, 4, 14]}
                workType={this.props.selectedWorkType}
              >
                <SmartRadioButtons
                  id="HVACDealer"
                  label="Have you selected an Equipment (HVAC) contractor?"
                  valueList={["YES", "NO"]}
                  valueLabelList={["Yes", "No"]}
                  selectedValue={getValue('onsiteInfo.hvacDealer')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.hvacDealer"})}
                  invalidFeedback="Please indicate if you selected an Equipment (HVAC) contractor."
                />
                <SmartTextBox
                  id="name"
                  label="Name"
                  maxLength="62"
                  value={getValue('onsiteInfo.hvacName')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.hvacName"})}
                  invalidFeedback="Please provide a valid Name."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.hvacDealer')]}
                  required
                />
                <SmartTextBox
                  id="hvacPhone"
                  label="Phone Number"
                  value={getValue('onsiteInfo.hvacPhone')}
                  type="phone"
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.hvacPhone"})}
                  invalidFeedback="Please provide a valid HVAC Phone Number."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.hvacDealer')]}
                />
                <SmartTextBox
                  id="company"
                  maxLength="50"
                  label="Company"
                  value={getValue('onsiteInfo.hvacCompany')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.hvacCompany"})}
                  invalidFeedback="Please provide a valid Company."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.hvacDealer')]}
                />
              </SmartSection>
              <SmartSection
                id="electricianSection"
                title="Electrician"
                workTypeShowList={[7, 8, 9, 14]}
                workType={this.props.selectedWorkType}
              >
                <SmartRadioButtons
                  id="electrician"
                  label="Electrician?"
                  valueList={["YES", "NO"]}
                  valueLabelList={["Yes", "No"]}
                  selectedValue={getValue('onsiteInfo.electrician')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.electrician"})}
                  invalidFeedback="Please provide a valid Electrician."
                />
                <SmartTextBox
                  id="electricianName"
                  maxLength="62"
                  label="Name"
                  value={getValue('onsiteInfo.electricianName')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.electricianName"})}
                  invalidFeedback="Please provide a valid Name."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.electrician')]}
                  required
                />
                <SmartTextBox
                  id="electricianPhone"
                  type="phone"
                  label="Phone"
                  value={getValue('onsiteInfo.electricianPhone')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.electricianPhone"})}
                  invalidFeedback="Please provide a valid Phone Number."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.electrician')]}
                />
                <SmartTextBox
                  id="electricianCompany"
                  maxLength="50"
                  label="Company"
                  value={getValue('onsiteInfo.electricianCompany')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.electricianCompany"})}
                  invalidFeedback="Please provide a valid Company."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.electrician')]}
                />
              </SmartSection>
              <SmartSection
                id="excavatorSection"
                title="Excavator Operator"
                multipleShowLists={[[true]]}
                multipleDeciders={[[6, 12, 13].includes(this.props.selectedWorkType) || ["NEWRESIDENTIAL", "NEWCOMMERCIAL"].includes(getValue('cusInfo.serviceType'))]}
              >
                <SmartRadioButtons
                  id="excavatorYesOrNo"
                  label="Have you selected an Excavator Operator?"
                  valueList={["YES", "NO"]}
                  valueLabelList={["Yes", "No"]}
                  selectedValue={getValue('onsiteInfo.excavator')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.excavator"})}
                  invalidFeedback="Please provide a valid selection."
                  required
                />
                <SmartTextBox
                  id="excavatorName"
                  maxLength="62"
                  label="Name"
                  value={getValue('onsiteInfo.excavatorName')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.excavatorName"})}
                  invalidFeedback="Please provide a valid Name."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.excavator')]}
                  required
                />
                <SmartTextBox
                  id="excavatorPhone"
                  type="phone"
                  label="Phone"
                  value={getValue('onsiteInfo.excavatorPhone')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.excavatorPhone"})}
                  invalidFeedback="Please provide a valid Phone Number."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.excavator')]}
                  required
                />
                <SmartTextBox
                  id="excavatorEmail"
                  maxLength="100"
                  label="Email"
                  type="email"
                  value={getValue('onsiteInfo.excavatorEmail')}
                  onChange={(event) => this.handleInputChange(event, {name: "onsiteInfo.excavatorEmail"})}
                  invalidFeedback="Please provide a valid Company."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('onsiteInfo.excavator')]}
                />
              </SmartSection>
              <Row>
                <div className="col-12">
                  <div className="text-center">
                    <button
                      disabled={this.props.updateInProgress}
                      type="button"
                      onClick={() => this.props.prevPage()}
                      className="btn btn-secondary btn-block text-uppercase mb-2 shadow-sm btn-sm me-2"
                    >
                      Previous
                    </button>
                    <button
                      disabled={this.props.updateInProgress}
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm"
                    >
                      Next
                    </button>
                  </div>
                  {this.props.updateInProgress && <div className="text-center"><Badge bg="primary" >Saving in progress... </Badge></div>}
                </div>
              </Row>
            </Row>
          }
        </div>
      </form>
    );
  }
}


export default CreateOnsiteRequest;